import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  const smallScreen = useMediaQuery('(max-width: 340px)');

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setIsExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ px: 2, mt: 4 }}>
      <Typography
        data-aos="fade-up"
        align="center"
        color="primary"
        sx={{ fontSize: '1.5rem', fontWeight: 900 }}
      >
        FAQ
      </Typography>
      <Box
        data-aos="fade-up"
        sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Accordion
          disableGutters
          expanded={isExpanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ boxShadow: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{
                fontSize: smallScreen ? '0.875rem' : '1rem',
                fontWeight: 600,
              }}
            >
              Min, garansi berapa tahun ya?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: smallScreen ? '0.875rem' : '1rem' }}>
              Untuk setiap pembelian Towerfan ini Bobbers akan mendapat garansi
              service 1 tahun yaa
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          expanded={isExpanded === 'panel2'}
          onChange={handleChange('panel2')}
          sx={{ boxShadow: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              sx={{
                fontSize: smallScreen ? '0.875rem' : '1rem',
                fontWeight: 600,
              }}
            >
              Min, pengiriman luar kota bisa?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: smallScreen ? '0.875rem' : '1rem' }}>
              Tentu saja bisa! Kami melayani seluruh Bobbers yang ada di
              Indonesia
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          expanded={isExpanded === 'panel3'}
          onChange={handleChange('panel3')}
          sx={{ boxShadow: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography
              sx={{
                fontSize: smallScreen ? '0.875rem' : '1rem',
                fontWeight: 600,
              }}
            >
              Min, bisa COD?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: smallScreen ? '0.875rem' : '1rem' }}>
              Untuk kawasan daerah Medan bisa pemesanan dan pembayaran melalui
              COD yaa Bobbers
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
export default Faq;
