import { Box, Typography, useMediaQuery } from '@mui/material';
import testimoni1 from '../../assets/testimoni_1.png';
import testimoni2 from '../../assets/testimoni_2.png';
import testimoni3 from '../../assets/testimoni_3.png';
import Carousel from 'react-material-ui-carousel';

const images = [testimoni1, testimoni2, testimoni3];

const Testimony = () => {
  const foldScreen = useMediaQuery('(max-width: 300px)');
  const smallScreen = useMediaQuery('(max-width: 340px)');

  return (
    <Box sx={{ bgcolor: 'primary.main', mt: -0.2, pt: 2, px: 2 }}>
      <Typography
        data-aos="fade-up"
        align="center"
        sx={{ color: '#fff', fontSize: '1.25rem', fontWeight: 900 }}
      >
        TESTIMONI
      </Typography>
      <Typography
        data-aos="fade-up"
        align="center"
        sx={{ color: '#fff', fontWeight: 500, mt: 2 }}
      >
        Kami selalu memberikan yang terbaik dalam melayani customer kami
      </Typography>
      <Box sx={{ mt: 4, pb: 4 }} data-aos="fade-up">
        <Carousel
          navButtonsAlwaysInvisible
          activeIndicatorIconButtonProps={{ style: { color: '#fff' } }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              sx={{
                width: '100%',
                height: foldScreen ? '450px' : smallScreen ? '500px' : '600px',
              }}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
export default Testimony;
