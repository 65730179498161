import { Box, Typography, useMediaQuery } from '@mui/material';
import carousel1 from '../../assets/carousel_1.png';
// import carousel2 from '../../assets/carousel_2.png';
import carousel3 from '../../assets/carousel_3.png';
import Carousel from 'react-material-ui-carousel';

const images = [carousel1, carousel3];

const Product = () => {
  const foldScreen = useMediaQuery('(max-width: 300px)');
  const smallScreen = useMediaQuery('(max-width: 340px)');

  return (
    <Box sx={{ mt: 4, px: 2 }} id="product">
      <Typography
        data-aos="fade-up"
        align="center"
        color="primary"
        sx={{ fontWeight: 900, fontSize: '1.25rem' }}
      >
        SAYA KENALI BOBBERS!
      </Typography>
      <Box data-aos="fade-up" sx={{ mt: 4, pb: 2 }}>
        <Carousel
          navButtonsAlwaysInvisible
          activeIndicatorIconButtonProps={{ style: { color: '#41A2DB' } }}
          indicatorIconButtonProps={{
            style: { marginInline: '4px' },
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              sx={{
                width: '100%',
                height: foldScreen ? '450px' : smallScreen ? '500px' : '600px',
              }}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
export default Product;
