import { Box, Button, Typography } from '@mui/material';
import tokopedia from '../../assets/tokopedia.png';
import shopee from '../../assets/shopee.png';
import kada from '../../assets/kada.png';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';

const icons = [
  { icon: tokopedia, link: 'https://tokopedia.link/bfC2n5oq2qb' },
  {
    icon: shopee,
    link: 'https://shopee.co.id/danmogot_official?smtt=0.480272058-1655807289.9',
  },
  { icon: kada, link: null },
];

const Contact = () => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Button
        data-aos="fade-up"
        fullWidth
        variant="contained"
        sx={{ borderRadius: '10px', py: 2, fontWeight: 900 }}
        onClick={() => {
          window.open('https://api.whatsapp.com/send?phone=62816332013');
        }}
      >
        HUBUNGI KAMI SEKARANG
      </Button>
      <Typography
        data-aos="fade-up"
        align="center"
        color="primary"
        sx={{ mt: 2, fontSize: '0.875rem', fontWeight: 500 }}
      >
        atau hubungi melalui
      </Typography>
      <Box
        data-aos="fade-up"
        sx={{ display: 'flex', justifyContent: 'space-evenly', gap: 1, mt: 2 }}
      >
        {icons.map((item, index) => {
          if (!item.link)
            return (
              <ClickAwayListener key={index} onClickAway={handleTooltipClose}>
                <Tooltip
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Coming Soon"
                >
                  <Box
                    key={index}
                    sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
                    component="img"
                    src={item.icon}
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              </ClickAwayListener>
            );
          return (
            <Box
              key={index}
              sx={{ height: '50px', cursor: 'pointer' }}
              component="img"
              src={item.icon}
              onClick={() => {
                window.open(item.link);
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};
export default Contact;
