import { Container } from '@mui/material';
import Contact from '../components/Landing/Contact';
import Faq from '../components/Landing/Faq';
import Footer from '../components/Landing/Footer';
import Header from '../components/Landing/Header';
import Product from '../components/Landing/Product';
import Solution from '../components/Landing/Solution';
import Why from '../components/Landing/Why';
import Testimony from '../components/Landing/Testimony';

const Landing = () => {
  return (
    <Container maxWidth="xs" disableGutters>
      <Header />
      <Solution />
      <Product />
      <Why />
      <Testimony />
      <Faq />
      <Contact />
      <Footer />
    </Container>
  );
};
export default Landing;
