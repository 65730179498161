import { Box, Typography } from '@mui/material';
import background from '../../assets/solution_background.png';

const Solution = () => {
  return (
    <Box sx={{ width: '100%', height: '300px' }}>
      <Box
        sx={{
          height: 1,
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          transform: 'skewY(175deg)',
          transformOrigin: 'left',
          px: 2,
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box data-aos="fade-up">
          <Typography
            align="center"
            sx={{
              color: '#fff',
              fontSize: '1.25rem',
              fontWeight: 900,
              transform: 'skewY(5deg)',
            }}
          >
            SOLUSI SUASANA SEJUK,
          </Typography>
          <Typography
            align="center"
            sx={{
              color: '#fff',
              fontSize: '1.25rem',
              fontWeight: 900,
              transform: 'skewY(5deg)',
            }}
          >
            CEPAT DINGIN DAN HEMAT ENERGI
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Solution;
