import { Box, Typography } from '@mui/material';

const Why = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          bgcolor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          py: 6,
          height: '360px',
        }}
      >
        <Box
          sx={{
            bgcolor: '#fff',
            height: 1,
            width: 1,
            transform: 'skewY(175deg)',
            display: 'flex',
            alignItems: 'center',
            px: 2,
          }}
        >
          <Box data-aos="fade-up">
            <Typography
              align="center"
              color="primary"
              sx={{
                fontSize: '1.25rem',
                fontWeight: 900,
                transform: 'skewY(5deg)',
              }}
            >
              KENAPA HARUS BERBELANJA DI DANMOGOT?
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Why;
