import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import background from '../../assets/header_background.png';
import danmogot from '../../assets/danmogot.png';

const Header = () => {
  const foldScreen = useMediaQuery('(max-width: 300px)');
  const smallScreen = useMediaQuery('(max-width: 340px)');

  return (
    <Box
      sx={{
        width: '100%',
        background: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        pb: 8,
        zIndex: 1,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-aos="fade-down"
          sx={{
            pb: 1,
            pt: 0.5,
            px: 2,
            bgcolor: '#fff',
            width: smallScreen ? '180px' : '200px',
            borderRadius: '0px 0px 30px 30px',
          }}
        >
          <Box component="img" src={danmogot} sx={{ width: 1 }} />
        </Box>
      </Box>
      <Box data-aos="fade-up" sx={{ width: 0.585, px: 2, mt: 2 }}>
        <Typography
          sx={{
            color: 'primary.contrastText',
            fontSize: foldScreen
              ? '1.375rem'
              : smallScreen
              ? '1.5rem'
              : '1.625rem',
            fontWeight: 900,
          }}
        >
          JADIKAN HUNIAN ANDA SEJUK DAN NYAMAN
        </Typography>
        <Box sx={{ width: '150px', mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: '10px',
              width: foldScreen ? '130px' : '160px',
              px: 2,
              py: 1,
            }}
            onClick={() => {
              window.open('https://api.whatsapp.com/send?phone=62816332013');
            }}
          >
            <Typography
              align="center"
              sx={{
                fontWeight: 700,
                fontSize: foldScreen ? '0.75rem' : '1rem',
              }}
            >
              BELI SEKARANG
            </Typography>
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: '10px',
              mt: 1,
              px: 2,
              py: 1,
              color: 'primary.contrastText',
              borderColor: 'primary.contrastText',
              width: foldScreen ? '130px' : '160px',
            }}
            onClick={() => {
              window.location.href = '#product';
            }}
          >
            <Typography
              align="center"
              sx={{
                fontWeight: 700,
                fontSize: foldScreen ? '0.75rem' : '1rem',
              }}
            >
              INFO PRODUK
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
